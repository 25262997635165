.profile-details-section p{
    color: #274ba0;
}

.details-outer-section {
    border-left: 1px solid #dd1d27;
}

.border-red{
    border: 1px solid #dd1d27;
}