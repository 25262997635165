.country-code-dropdown{
    border: 1px solid rgba(39, 75, 160, 0.65);
    padding-left: 10px;
}

/* STUDENT PENDING REGISTRATIONS */
.status-section{
width: max-content;
border-radius: 3px;
}

/* REGISTRATION LIST */

.mail-dropdown{
    box-shadow: none !important;
}

.mail-dropdown i{
    font-size: 2.4em;
}

.control-buttons-section .rw-combobox .rw-widget-container {
    display: none;
}

.text-muted .rw-combobox .rw-widget-container{
    /* display: none; */
}

/* .text-muted .rw-popup-transition{
    width: 200px !important;
} */

.color-button-green{
    border: 1.3px solid rgb(76, 174, 76);
    color: rgb(76, 174, 76) ;
    border-radius: 3px;
}
.color-button-blue{
    border: 1.3px solid #274ba0;
    color: #274ba0 ;
    border-radius: 3px;
}
.color-button-red{
    border: 1.3px solid #dd1d27;
    color: #dd1d27 ;
    border-radius: 3px;
}

.control-buttons-section .rw-popup-transition{
    width: 200px !important;
    font-size: 0.75rem !important;
}

/* .text-muted .rw-combobox .rw-widget-container {
    display: none;
} */

.rw-list{
    font-size: 0.8em !important;
}

.notification-circle{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 48px;
}

.control-buttons-section{
    position: relative;
}


.rw-multiselect-tag{
    background-color: #274ba0 !important;
    color: white;
}

.sticky-row{
    position: sticky;
    top: 3.7rem;
    z-index: 2;
}
