.la-line-scale-pulse-out,
.la-line-scale-pulse-out > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.la-line-scale-pulse-out {
  display: block;
  font-size: 0;
  color: #0055a6;
  margin: 0 auto;
}
// .loader-wrapper{
//   background: '#fff';
// }
// .loader-overlay{
//   background-size: 10%;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-image: url('../img/RamLogo.png');
// }

.la-line-scale-pulse-out > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-line-scale-pulse-out {
  width: 40px;
  height: 32px;
}
.la-line-scale-pulse-out > div {
  width: 4px;
  height: 32px;
  margin: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-animation: line-scale-pulse-out .9s infinite cubic-bezier(.85, .25, .37, .85);
  -moz-animation: line-scale-pulse-out .9s infinite cubic-bezier(.85, .25, .37, .85);
  -o-animation: line-scale-pulse-out .9s infinite cubic-bezier(.85, .25, .37, .85);
  animation: line-scale-pulse-out .9s infinite cubic-bezier(.85, .25, .37, .85);
}
.la-line-scale-pulse-out > div:nth-child(3) {
  -webkit-animation-delay: -.9s;
  -moz-animation-delay: -.9s;
  -o-animation-delay: -.9s;
  animation-delay: -.9s;
}
.la-line-scale-pulse-out > div:nth-child(2),
.la-line-scale-pulse-out > div:nth-child(4) {
  -webkit-animation-delay: -.7s;
  -moz-animation-delay: -.7s;
  -o-animation-delay: -.7s;
  animation-delay: -.7s;
}
.la-line-scale-pulse-out > div:nth-child(1),
.la-line-scale-pulse-out > div:nth-child(5) {
  -webkit-animation-delay: -.5s;
  -moz-animation-delay: -.5s;
  -o-animation-delay: -.5s;
  animation-delay: -.5s;
}
/*
 * Animation
 */
@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(.3);
    transform: scaley(.3);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@-moz-keyframes line-scale-pulse-out {
  0% {
    -moz-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -moz-transform: scaley(.3);
    transform: scaley(.3);
  }
  100% {
    -moz-transform: scaley(1);
    transform: scaley(1);
  }
}
@-o-keyframes line-scale-pulse-out {
  0% {
    -o-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -o-transform: scaley(.3);
    transform: scaley(.3);
  }
  100% {
    -o-transform: scaley(1);
    transform: scaley(1);
  }
}
@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    -moz-transform: scaley(1);
    -o-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(.3);
    -moz-transform: scaley(.3);
    -o-transform: scaley(.3);
    transform: scaley(.3);
  }
  100% {
    -webkit-transform: scaley(1);
    -moz-transform: scaley(1);
    -o-transform: scaley(1);
    transform: scaley(1);
  }
}


.loader-wrapper{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  .loader-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.6);

  }
  .la-line-scale-pulse-out{
    position: absolute;
    z-index: 101;
    top:50%;
    left: 50%;
    transition: translate(-50%);
  }
}

@media (max-width:768px){
  .loader-overlay{
    background-size: 25%;
  }
}