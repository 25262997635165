.main{
    margin: 0px;
    padding: 0px;
    width: 100%;
}

.main-wrapper{
    padding: 10px 6%;
}

/* .right-section{
    height: 80vh;
    overflow: auto;
} */

.course-section,.course-content,.course-feature-section,.instructor-card, .review-form{
    box-shadow: 0 15px 20px 0 rgba(0,0,0,.1);
    border-radius: 7px;
}

.course-heading h4{
    font-size: 32px;
}

.common-card{
    background-color: #fff;
    border: 1px solid #e5e5e5;
}

.course-section{
    height: 57vh;
    min-height: 300px;
    overflow: auto;
    background-color: #fff;
}
.course-content{
    background-color: #fff;
    overflow: auto;
    height: 16vh;
}
.course-section i{
    font-size: 24px;
}

.course-section p{
   margin-bottom: 0px;
}

.course-author{
    border-right: 1px solid #e5e5e5;
}

.course-category-name p:first-child , .course-author-name p:first-child{
    color:#ced4da;
}

.course-image{
    background-color: grey;
    width: 100%;
    overflow: hidden;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.course-image img{
    width: 100%;
 
}

.course-content p , .course-content ul li{
    color: #7d7e7f;
}

.course-content h5{
    color: #464749;
}

.chapter-padding{
    border-right: none !important;
    padding-right: none;
}

.chapter-padding p{
    margin: 0px;
}

.chapter-list li{
    padding: 10px 10px 10px 0px;
    list-style: none;
    border-top: 1px solid #e9ecef;
}

.private-lesson p{
    margin: 0px;
}

.chapter-content p:first-child{
    font-weight: 500;
    color:#464749;
}

.send-button{
    max-width: 200px;
    border-radius: 30px;
    background-color: #274ba0 !important;
    margin: 0 auto !important;
}

.cart-button{
    width: 80%;
    background-color:#274ba0 !important;
    color:#fff !important;
    margin: 0 auto;
    border-radius: 30px;
    font-weight: 400
}

.cart-button:hover{
    background-color: #0055a6 !important;
    color:#fff !important;
}

.price-section p{
    margin: 0;
}

.price-section p:first-child{
    color: #666c73;
}

.price-section p{
    font-size: 0.8rem !important;
}

.price-section p strong{
    font-size: 26px;
}

.price-section i{
    color: #274ba0 ;
}

.course-feature-list {
    list-style: none;
}

.course-features-list li{
    padding: 15px 10px 15px 0px;
    text-align: left;
    list-style: none;
    border-bottom: 1px solid #e9ecef;
    color: #7d7e7f;
}

.instructor-card{
    min-width: 200px;
}

.instructor-image{
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: gray;
}

.instructor-image img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.instructor-card p{
    margin: 0px;
}

.instructor-subject p{
    color: #666c73;
}

.instructor-card a{
    border-bottom: 1px solid #e5e5e5;
}

.course-heading {
    font-size: 1rem !important;
}

#courseContent{
    font-weight: 500;
}
#courseContent [data-slate-object="block"]{
    padding: 0;
}
.color-blue{
    color: #274ba0;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:grey; 
    opacity: 0.2;
  }

  @media (min-width: 1400px){
    .course-features-wrapper .price-section p{
        font-size: .85rem !important;
    }
    .course-features-wrapper .cart-button, .course-info span{
        font-size: .8rem !important;
    }
  }

  @media (min-width: 1700px){
    .course-features-wrapper .price-section p{
        font-size: .95rem !important;
    }
    .course-features-wrapper .cart-button,.course-info span{
        font-size: .9rem !important;
    }
  }