/*-----------GLOBAL STARTS--------------*/

body,.page-wrapper{
	background-color:#fcfaff !important;
	background-image: url('../img/Background1.png');
}

/*PRIMARY COLOR  #274ba0*/
/*SECONDARY COLOR #dd1d27*/
/*TERTIARY COLOR #ffa500*/

#root{
	height:100%
}
.bg-theme-blue{
	background-color: #274ba0 !important;
}
.bg-theme-red{
	background-color: #dd1d27 !important;
}
.bg-theme-orange{
	background-color: #ffa500 !important;
}
.text-theme-blue{
	color: #274ba0 !important;
}
.text-theme-red{
	color: #dd1d27 !important;
}
.text-theme-orange{
	color: #ffa500 !important;
}
.text-theme-green{
	color: #00c851 !important;
}
.border-theme-blue{
	border: 1px solid#274ba0 !important;
}
.border-theme-red{
	border: 1px solid#dd1d27 !important;
}
.border-theme-orange{
	border: 1px solid#ffa500 !important;
}

.body-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
	background: #fcfaff;
	opacity: 0.9;
}
.body-overlay.dashboard-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
	background: #fcfaff;
	opacity: 0.95;
}
span{
	font-family: 'Roboto';
}
a,td,th,p,input,textarea{
	font-family: 'Roboto' !important
}

h1,h2,h3,h4,h5,h6,label{
	color: #274ba0 !important;
	font-family: 'Roboto' !important
}
h6{
	font-size: .8rem !important;
}
h5{
	font-size: .9rem !important;
}
h4{
	font-size: 1rem !important;
}

body {
	font-size: 13px;
	color: #2f3245;
	letter-spacing: .02rem;
	background: #f4f7fe;
}
input,span,p,td,th,label,textarea{
	font-size: .75rem !important;
}
.btn{
	font-size: .7rem !important;
}
a{
	font-size: .7rem;
	color: #274ba0 !important
}

.notification-container a, .notification-container span, .notification-container span h4{
	color: #fff !important
}

.invalid-feedback{
	font-size: .75rem !important
}

th{
	font-weight: bold !important
}

.w-0{
	width: 0 !important;
}
.w-10 {
	width: 10% !important;
}
.w-15{
	width: 15% !important;
} 
.w-20 {
	width: 20% !important;
}
.w-30 {
	width: 30% !important;
}
.w-40 {
	width: 40% !important;
}
.w-50 {
	width: 50% !important;
}
.w-60 {
	width: 60% !important;
}
.w-65 {
	width: 140px !important;
}
.w-70 {
	width: 70% !important;
}
.w-80 {
	width: 80% !important;
}
.w-90 {
	width: 90% !important;
}
.w-100 {
	width: 100% !important;
}

.component-loading{
    height: 100%;
	/* min-height: calc( 100vh - 60px); */
	min-height: 100vh;
	padding-top: 100px;
}

.header {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: #FC466B;
    position: fixed;
    height: 60px!important;
    overflow: hidden;
    z-index: 10;
}
.main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}

.main-wrapper{
	height: 100%;
}
/*-----------GLOBAL ENDS--------------*/

/*-----------------DASHBOARD SIDEBAR----------*/

.sidebar li a{
	color: #fff !important;
}
.sidebar{
	width: 0;
	overflow-x: hidden;
	transition: all ease-in-out 0.5s;
}
.sidebar .nav-link p{
	font-size: 0.8rem !important;
}
.sidebar-wrapper .logo{
	background-color: rgba(255, 255, 255, 0.23);
}
.sidebar, .main-panel {
    overflow: auto;
    max-height: 100%;
    height: 100%;
    -webkit-transition-property: top, bottom;
    transition-property: top, bottom;
    -webkit-transition-duration: .2s, .2s;
    transition-duration: .2s, .2s;
    -webkit-transition-timing-function: linear, linear;
    transition-timing-function: linear, linear;
    -webkit-overflow-scrolling: touch;
}
.sidebar.show-drawer, body>.navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15vw;
	display: block;
	overflow-x: hidden;
    z-index: 1;
    color: #fff !important;
    font-weight: 200;
    background-size: cover;
    background-position: center center;
}
.sidebar.show-drawer .sidebar-wrapper, body>.navbar-collapse .sidebar-wrapper {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    width: 15vw;
    z-index: 4;
    padding-bottom: 100px;
}
.sidebar, body>.navbar-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
	display: block;
	overflow-x: hidden;
    z-index: 1;
    color: #fff;
    font-weight: 200;
	background-size: cover;
	transition: all ease-in-out 0.5s;
    background-position: center center;
}
.sidebar .sidebar-background, body>.navbar-collapse .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}
.sidebar .logo, body>.navbar-collapse .logo {
    padding: 10px 15px 9px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 4;
}
.sidebar .logo, body>.navbar-collapse .logo {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .logo .simple-text, body>.navbar-collapse .logo .simple-text {
    /* text-transform: uppercase; */
    padding: 5px 0px;
    display: block;
	font-size: 0.9rem;
	font-weight:bold;
	letter-spacing:0.02rem;
    color: #FFFFFF;
    text-align: center;
    font-weight: 400;
    line-height: 30px;
}

.sidebar .logo .simple-text, body>.navbar-collapse .logo .simple-text {
    /* text-transform: uppercase; */
    padding: 5px 0px;
    display: block;
	font-size: 0.9rem;
	font-weight:bold;
	letter-spacing:0.02rem;
    color: #FFFFFF;
    text-align: center;
    font-weight: 400;
    line-height: 30px;
}
.sidebar .nav, body>.navbar-collapse .nav {
    margin-top: 20px;
    float: none;
    display: block;
}
.sidebar .nav a, .table>tbody>tr .td-actions .btn {
    -webkit-transition: all 150ms ease-in;
    -moz-transition: all 150ms ease-in;
    -o-transition: all 150ms ease-in;
    -ms-transition: all 150ms ease-in;
    transition: all 150ms ease-in;
}
.sidebar .nav li .nav-link, body>.navbar-collapse .nav li .nav-link {
    color: #FFFFFF;
    margin: 5px 15px;
    opacity: .86;
    border-radius: 4px;
    display: block;
    padding: 10px 15px;
}
.sidebar .nav li.active .nav-link, body>.navbar-collapse .nav li.active .nav-link {
    color: #FFFFFF;
    opacity: 1;
    background: rgba(255, 255, 255, 0.23);
}
.sidebar .nav .active-pro, body>.navbar-collapse .nav .active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav .nav-link, body>.navbar-collapse .nav .nav-link {
    color: #FFFFFF;
    margin: 5px 15px;
    opacity: .86;
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    /* text-transform: uppercase; */
    line-height: 30px;
	font-weight:500;
	letter-spacing:0.03rem;
}
.sidebar:after, .sidebar:before, body>.navbar-collapse:after, body>.navbar-collapse:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}
.sidebar:before, body>.navbar-collapse:before {
    opacity: .33;
    background: #000000;
}
.sidebar:after, body>.navbar-collapse:after {
    background: #9368E9;
    background: -moz-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9368E9), color-stop(100%, #943bea));
    background: -webkit-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -o-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -ms-linear-gradient(top, #9368E9 0%, #943bea 100%);
    /* background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%); */
	background: linear-gradient(to bottom, #274ba0 0%, #274ba0 100%);
	background-size: 150% 150%;
    z-index: 3;
    opacity: 1;
}
.sidebar[data-image]:after, .sidebar.has-image:after, body>.navbar-collapse[data-image]:after, body>.navbar-collapse.has-image:after {
    opacity: .77;
}


.sidebar-background{
	background-image: url('../img/Sidebar/SidebarBg2.jpg');
	height: 100%;
}

.page-header.dashboard-header.show-drawer .main-navbar{
	margin-left: 15vw;
	transition: margin-left .5s;
}
/*-----------------DASHBOARD SIDEBAR ENDS----------*/

.page-content.dashboard-content {
	/* background-color:#fcfaff; */
	transition: margin-left .5s;
	padding: 20px;
	margin-top: 60px;
}  
.page-content.dashboard-content.show-drawer {
	margin-left: 15vw;
	/* background-color:#fcfaff; */
	transition: margin-left .5s;
	padding: 20px;
}
.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}
.main-navbar .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
}
.main-navbar .header-brand .header-brand-img {
	margin-right: 0;
}
.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
}
.app-header1 .header-brand .header-brand-img {
	margin-right: 0;
}
.header-brand:hover {
	color: inherit;
	text-decoration: none;
}
.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
	position: relative;
	top: -3px;
}

.sidenav-toggled .main-footer {
	padding: 20px 30px 20px 0px;
	margin-left:90px;
}

.dropdown-menu {
	border: none;
	-webkit-box-shadow: 0 0 25px rgba(0, 0, 0, .15);
	box-shadow: 0 0 25px rgba(0, 0, 0, .15);
}
.dropdown-menu a {
	font-size: 14px !important;
}

.user-dropdown{
	font-size: 0.8rem !important;
}
.dropdown-menu a:hover{
	background-color:#3454f5;
	color:#fff;
}
.dropdown-item {
	padding: 10px 20px;
	line-height: 1.5;
	color: #3454f5;
}
.dropdown-divider {
	background-color: #f0f0f2;
	border-top: 1px solid #f0f0f2;
}
.dropdown-list {
	width: 350px;
	padding: 0;
}
.dropdown-list .dropdown-item:first-child {
	border-top: 1px solid #f0f0f2;
}
.dropdown-list .dropdown-item {
	display: inline-block;
	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 12px;
	border-bottom: 1px solid #f0f0f2;
}
.dropdown-list .dropdown-item.dropdown-item-unread:active .dropdown-item-desc, .dropdown-list .dropdown-item.dropdown-item-unread:active .dropdown-item-desc b {
	color: #ab7fa5;
}
.dropdown-list .dropdown-item:active .dropdown-item-desc, .dropdown-list .dropdown-item:active .dropdown-item-desc b {
	color: #ab7fa5;
}
.dropdown-list .dropdown-item.dropdown-item-unread {
	background-color: #f4f7fe;
	border-bottom-color: #f0f0f2;
}
.dropdown-list .dropdown-header {
	letter-spacing: .5px;
	font-weight: 500;
	padding: 15px;
	color: #3454f5;
	font-size: 14px;
}
.dropdown-item .dropdown-item .has-icon:hover {
	background-color: #f4f7fe;
}
.dropdown-list .dropdown-item.dropdown-item-header:hover {
	background-color: #f4f7fe;
}
.dropdown-list .dropdown-item:hover {
	background-color: #f4f7fe;
}
.dropdown-item :focus {
	background-color: #f4f7fe;
}
.dropdown-list .dropdown-item .time {
	margin-left: 30px;
	font-weight: 500;
	font-size: 10px;
}
.dropdownmsg {
	margin-top: 5px;
}
.dropdown-list .dropdown-item .dropdown-item-img {
	float: left;
	width: 40px;
}
.dropdown-list .dropdown-item i {
	float: left;
	font-size: 20px;
}
.dropdown-list .dropdown-item .dropdown-item-desc {
	margin-left: 60px;
	white-space: normal;
	color: #24273c;
}
.dropdown-list .dropdown-item .dropdown-item-desc b:hover{
	color:#3454f5;
}
.dropdown-list .dropdown-item .dropdown-item-desc p {
	margin-top: 5px;
	margin-bottom:0;
}
.dropdown-list .dropdown-list-content {
	overflow: hidden;
}
.navbar .dropdown-menu-right{
	border-color: #ccc;
	color:#777;
	position: absolute;
    top: 2rem !important;
    left: -4rem !important;
    z-index: 1000;
    display: none;
    transform: none !important;
}

.dropdown-menu-right span{
	font-size: 14px !important;
}

.navbar-dropdown__icon{
	width: 18px !important;
}

.hamburger__icon{
	width: 20px !important;
}

.navbar .dropdown-menu-right:before {
    content:"";
    position: absolute;
    right: 11px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    z-index:9999;
}
.navbar-nav .dropdown-item{
	color:#24273c;
}
.navbar-nav .dropdown-menu a:hover{
	background-color:#f4f7fe !important;
	/* color:#3454f5; */
	color: #01cf6b
}
.navbar-nav .dropdown-menu i{
	/* color:#3454f5; */
	color: #01cf6b
}
.navbar .dropdown-menu-right:before{
	left: auto;
	right: 12px;
}
.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}
/*------- NAVBAR STARTS---------------*/

.navbar {
	height: 60px;
	left: 0;
	top: 0;
	right: 0;
	position: fixed !important;
	z-index: 890;
	background: #fff;
	color: #fff;
	padding-top: 0;
	padding-bottom: 0;
	box-shadow: 0px -1px 6px 0px rgba(0,0,0,0.1) !important;
}
.navbar-home{
	box-shadow: 0 0px 7px 0px rgba(0,0,0,0.16), 0 -1px 8px 0 rgba(0,0,0,0.12) !important;
}

.navbar.active {
	background-color: #3454f5;
}
.navbar .form-inline .form-control {
	background: #f4f7fe;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    color: #24273c;
    padding-left: 20px;
	padding-right: 0;
	margin-right: -6px;
	width: 200px;
	border-bottom-right-radius: 0;
    border-top-right-radius: 0;
	border-right: 0;
}

.navbar-right{
	height: 100%;
	align-items:center
}
.navbar-nav .input-icon-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 2.5rem;
    pointer-events: none;
    color: #fff;
}
.navbar .nav-link {
	color: #2f3245;
	padding-left: 15px !important;
	padding-right: 15px !important;
}
.navbar .nav-link.nav-link-lg div {
	margin-top: 3px; 
	margin-left: 5px;
	color: #274ba0
}
.dashboard-header .hamburger{
	height: 100%;
	padding-left: 0 !important;
}
.navbar .nav-link.nav-link-lg i {
	font-size: 20px;
	margin-top: 6px;
	color: #274ba0;
}

.dropdown .dropdown-list-toggle{
	display:inline;
	color: #777 !important;
}
.navbar .nav-link:hover {
	color: #3454f5;
}
.navbar .nav-link.disabled {
	color: #fff;
	opacity: .6;
}

.color-grey{
	color : #777777 !important;
}
/*- END NAVBAR --*/

@media(min-width:1024px){
	.navbar .form-inline .form-control{
		width:180px
	}
}

/*-----Responsive-------------*/

@media (max-width: 575.98px) {
	.navbar .nav-link.nav-link-lg div {
		display: none;
	}
	.navbar .form-inline .form-control {
		background: rgb(225,225,225);
		border: 1px solid rgb(225,225,225);
		color: #232323;
	}
	.dropdown-list-toggle {
		position: static;
		display: none;
	}
	.main-navbar .header-brand {
		min-width: 130px;
	}
	.dropdown-list-toggle .dropdown-list {
		left: 10px !important;
		width: calc(100% - 20px);
	}
}

@media (max-width: 414px) {
	.navbar .nav-link.nav-link-lg i {
		font-size: 20px;
		margin-top: 5px;
		padding-left:10px;
	}
}
@media (max-width: 1024px) {
	.main-content {
		padding-left: 30px;
	}
	.navbar {
		left: 0x;
		right: 0;
	}
	.navbar .dropdown-menu {
		position: absolute;
	}
	.navbar .navbar-nav {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-right {
		right: 0;
		left: auto;
		border:none !important;
		box-shadow:0 15px 20px 0 rgba(0,0,0,0.1) !important
	}
}

/*---login----*/

.login-main{
	height: calc( 100vh - 150px );
}

.login-banner{
	height: 100%;
	background-image: url('../img/Login/LoginBanner1.jpg');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.login-banner-text,.registration-banner-text{
	position: absolute;
	top: 0;
	left:0;
	bottom:0;
	right:0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.login-banner-text p,.registration-banner-text p{
	text-align: center;
	font-weight: 400 !important;
	font-size: 0.9rem !important;
}

.login-div a,.login-div label{
	font-weight: 400;
}
.login-submit-btn{
	color: #274ba0 !important;
	background-color: #fff !important;
}

.login-wrap{
	width: 100%;
    margin: 0 auto;
    max-width: 756px;
    /* height: 100%; */
    /* border-radius: 30px; */
    box-shadow: 0 12px 15px 0 rgba(0,0,0,0.74), 0 17px 50px 0 rgba(0,0,0,1);
}

.login-wrap a{
	font-size: 0.7rem !important;
}
.login-wrap .sign-in-htm,
.login-wrap .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	backface-visibility:hidden;
	transition:all .4s linear;
}
.login-wrap .sign-in,
.login-wrap .sign-up,
.login-form .group .check{
	display:none;
}
.login-wrap .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-wrap .tab{
    font-size: 20px !important;
    padding-left: 8px !important;
    margin-right: 15px;
    margin: 0 15px 10px 0;
    display: inline-block;
    border-radius: 2px;
    border-width: 2.5px !important;
    border-color: #cf1a29;
}
.login-wrap .sign-in:checked + .tab,
.login-wrap .sign-up:checked + .tab{
	color:#274ba0;
	border-color:#00a3db;
}
.login-form{
	min-height:218px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button,
.login-form .group .input-group-text{
	color:#274ba0;
	display:block;
}
.login-form .group .input{
	padding: 20px 12px !important;
	border: 1px solid rgba(39, 75, 160, 0.65);
}

.login-form .group .button{
	padding:12px;
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label{
	color:#777;
	font-size:.75rem;
	font-weight: 500;
}
.login-form .group .invalid-feedback{
	font-weight: 500;
}

.login-form .group .login-submit-btn{
	background:#274ba0 !important;
	border: none !important;
	color:#fff !important
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-wrap .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}

.forgot-pass-success-message{
	color: #274ba0;
	border-radius: 4px !important;
	border-color: #274ba0;
	font-weight: 500;
	font-size: .85rem;
}

/*LOGIN ENDS*/

.sidemenu-banner{
	width: 100px !important;
	text-align: center;
    margin: 0 auto;
}
.w-90{
	width: 80% !important;
}

.sweet-alert p{
	z-index: unset !important;
}

@media(max-width:991px){
	.page-header.dashboard-header.show-drawer .main-navbar,.page-content.dashboard-content.show-drawer{
		margin-left: 0;
		transition: none;
	}
	.sidebar{
		width: 0 !important;
		transition: all ease-in-out 0.5s;
	}

	.sidebar.show-drawer,.sidebar.show-drawer .nav,.sidebar.show-drawer .nav .nav-item{
		width: 230px !important;
		overflow-x: hidden;
	}
	.sidebar.show-drawer .nav .nav-item .nav-link{
		width: 208px !important;
		overflow-x: hidden;
	}
	.sidebar.show-drawer .sidebar-wrapper{
		width: 230px !important;
		padding-top: 60px !important
	}
	.sidebar-background,.sidebar[data-image]:after,.sidebar:before{
		margin-top: 60px;
	}
}

/*Media Queries for Sidebar*/

@media(min-width:1400px){
	.sidebar .nav-link p,.user-dropdown{
		font-size: .85rem !important;
	}
}

@media(min-width:1700px){
	.sidebar .nav-link p,.user-dropdown{
		font-size: .95rem !important;
	}
}

/*FOOTER STARTS*/
.page-footer-div{
	background-color: #274ba0;
	color: #fff;
	position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.page-footer-div.show-drawer{
	background-color: #274ba0;
	color: #fff;
	margin-left: 15vw;
}
.footer-copyright{
	font-family: 'Roboto';
	font-size: 0.8rem !important;
}
/*FOOTER ENDS*/

/*PAGINATION STYLE*/
.cs-pagination {
	white-space: nowrap;
	padding: 5px;
	color: #303133;
	font-weight: bold;
	font-size: 1.875rem;
	text-align: right;
	list-style-type: none;
}
.cs-pagination_item {
	color: #606266;
	border-radius: 2px;
	vertical-align: top;
	display: inline-block;
	font-size: 13px;
	height: 28px;
	line-height: 28px;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	margin: 0 5px;
	padding: 0;
}
.cs-pagination_item a {
	padding: 0 8px;
	display: inline-block;
	color: inherit !important;
	min-width: 22px;
	background-color: #f8fafb;
	outline: none;
	border: 1px solid #274ba0;
	border-radius: 2px;
}
.cs-pagination_item.active a {
	background-color: #4e37a0;
	color: #ffa500 !important;
}
.cs-pagination .previous, .cs-pagination .next {
	color: #606266;
	border: 1px solid #274ba0;
	border-radius: 18px;
	vertical-align: top;
	font-size: .9rem;
	font-weight: normal;
	/* height: 28px; */
	line-height: 28px;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
	display: inline-block;
	margin: 0 5px;
	padding: 0;
	background-color: #f8fafb;
}
.cs-pagination .previous a, .cs-pagination .next a {
	padding: 0 12px;
	display: inline-block;
	color: inherit !important;
	min-width: 22px;
	outline: none;
}
.cs-pagination .previous.disabled, .cs-pagination .next.disabled {
	color: #9eaec0 !important;
	cursor: not-allowed;
}
/*PAGINATION ENDS*/

/*SweetAlert Starts*/
.sweet-alert{
	padding: 0.6rem !important;
}
.sweet-alert .text-muted.lead{
	font-size: .8rem !important;
	font-weight: 500 !important;
}
.sweet-alert a{
	color: #fff !important;
	padding: .8rem 1.2rem !important;
}
.sweet-alert h2{
	font-weight:400 !important
}
/*SweetAlert Ends*/

/*Modal Starts*/
.modal-dialog{
	min-width:600px
}
.modal-dialog .modal-content{
	border-radius: .4rem !important;
}

.modal-dialog .modal-content .modal-header{
	border-top-left-radius: .4rem !important;
    border-top-right-radius: .4rem !important;
}
.rw-calendar-popup.rw-popup-container{
	right: 0 !important;
	left: -150px !important;
}
.add-course-form .rw-calendar-popup.rw-popup-container{
	left: 0 !important;
}
.rw-multiselect-tag{
	margin-top: 0 !important;
}
.rw-multiselect .rw-widget-input{
	padding: .5rem 0 !important;
}

.file-dropzone p{
	border: 1.5px dotted #ccc !important;
}

/*LISTING STYLES*/
.student-registration-details .item-html{
	max-height: calc( 100vh - 350px);
	overflow: auto;
}