.course-registration-accordian{
    background-color: white !important;

}

/* STUDENT COURSE REGISTRATION STARTS HERE. */

#teacherEmail_createlist_option{
	display: none;
}


/* STUDENT COURSE REGISTRATION ENDS HERE. */

.course-details-heading{
	background-color: #274ba0;
}

#selectFilter .rw-widget-input
{
    padding: .3rem 0 !important
}

#courseRegistrationDetailsDiv p, #courseRegistrationDetailsDiv td,#courseRegistrationDetailsDiv th,#courseRegistrationDetailsDiv a,#courseRegistrationDetailsDiv span{
    font-size: .9rem !important;
}
