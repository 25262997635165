
/* COURSES LIST ADDITIONAL CSS */

.control-buttons{
    background: none;
    border: none;
    color:#274ba0 !important ;
}

.course-button{
    padding: 10px 5px;
    background-color: #274ba0 !important;
}

/* STUDENTS LIST ADDITIONAL CSS */

.color-button{
    padding: 10px 5px;
    background-color: #274ba0 !important;
    border-radius: 3px;
}
.cancel-color-button{
    padding: 10px 5px;
    background-color: #dd1d27 !important;
    border-radius: 3px;
}
.modal-buttons, .modal-buttons:hover{
    background: none !important;
    border: none !important;
    box-shadow: none;
}


/* STUDENTS EDIT PROFILE FORM */

 #pills-tab .active{
    background-color: #ffa500 !important;
    color: white !important;
}

.country-code .rw-widget-picker{
    height: 32px !important;
}

.course-registration-form .student-details-tab.nav-link{
    font-size: .75rem !important;
	letter-spacing: .04rem;
	cursor: not-allowed;
}