.admin-dashboard-filters .nav-link.active{
    color: #fff !important;
    background-color: #ffa500;
}

.admin-dashboard-filters label{
    font-size: 0.8rem !important;
    font-weight: 500;
}
.admin-dashboard-filters .nav-link{
    font-size: 0.8rem;
    padding: 5px 17px;
    font-weight: 500;
    border-radius: 25px !important;
}

.admin-dashboard-tabs{
    border: 1px solid #274ba0;
    border-radius: 25px;
}

.admin-dashboard-tabs-content>.active{
    display: flex;
}

.admin-dashboard-courses .course-card{
    border-radius: 7px !important;
}

.admin-dashboard-courses .course-card u{
    color: #274ba0;
}

.admin-dashboard-courses .course-card.priority-1{
    border-left: 3px solid #dd1d27 !important;
}

.admin-dashboard-courses .course-card.priority-2{
    border-left: 3px solid #ffa500 !important;
}

.admin-dashboard-courses .course-card.priority-3{
    border-left: 3px solid #274ba0 !important;
}

.admin-dashboard-courses .course-card.priority-4{
    border-left: 3px solid lightseagreen !important;
}

.admin-dashboard-courses .course-card.priority-5{
    border-left: 3px solid grey!important;
}

.admin-dashboard-courses .modal-body{
    overflow: auto;
    height: 75vh;
}

.search-course-input input,.admin-dashboard-filters select{
    height: 37px;
}

.filter-toggle-button:focus{
    outline: none;
}

