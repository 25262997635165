#certificateDiv{
    position: absolute;
    bottom: -700px;
}
#certificateDiv [data-slate-void], [data-slate-void] img{
    display: inline-block;
}
#certificateEditorWrapper .editor span,#certificateDiv .editor span{
    font-family: 'Montserrat' !important;
}
#certificateEditorWrapper .editor strong span{
    font-weight: bold;
}

#certificateEditorWrapper .editor h1 span,#certificateEditorWrapper .editor h2 span,#certificateDiv .editor h1 span,#certificateDiv .editor h2 span{
    font-family: 'Bebas Neue', cursive !important;
}
#certificateDiv .editor{
    background-image:url('../img/certificateBg.jpg');
} 