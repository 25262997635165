.css-1d8w8c2 {
    position: relative;
    padding: .3rem .4rem!important;
    background-color: #274ba0;
    margin: 0 !important;
    border-bottom: 0 !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.css-1vdn1ty{
    padding: .2rem .25rem;
}

.css-vbnk1l{
    background-color: #ffa500;
    /* opacity: 0.6; */
    padding: .2rem .25rem;
    border-radius: .2rem;
    border-radius: 5px;
}

.css-139ucss {
    background-color: transparent !important;
    /* opacity: 0.9; */
    color: #fff;
    margin-top: 0px !important;
    padding: .5rem !important;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

span.material-icons{
    color: #fff !important;
    font-size: 1.2rem !important;
}

blockquote {
    border-left: 2px solid #ddd;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
  
blockquote[dir='rtl'] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid #ddd;
}
  
  [data-slate-editor] > * + * {
    /* margin-top: 1em; */
  }

  [data-slate-editor] input,[data-slate-editor] span,[data-slate-editor] p,[data-slate-editor] td,[data-slate-editor] th,[data-slate-editor] label,[data-slate-editor] textarea{
    font-size:.85rem !important
  }
  [data-slate-editor] h1,[data-slate-editor] h2{
      margin-bottom:0 !important;
  }
  [data-slate-editor] h1 span{
    font-size: 1.5rem !important;
  }
  [data-slate-editor] h2 span{
    font-size: 1.25rem !important;
  }
  [data-slate-editor]{
    min-height: 40vh;
    height: 70vh;
    overflow: auto;
    padding: 25px 0;
    background-color: #f0fbfa;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .add-course-editor [data-slate-editor]{
    height: 45vh;
  }

  [data-slate-object="block"]{
    padding: 0 25px;
  }
  
  [data-slate-void], [data-slate-void] img {
    padding: 0;
  }
  [data-slate-editor] img{
    width: inherit;
    margin: 10px;
    height: auto;
    max-height: inherit !important;
  }
  
  [data-slate-editor] blockquote{
    margin-left: 25px;
  }

#courseFields_taglist .rw-multiselect-tag-btn.rw-btn{
    display: none !important;
}

#studentFields_taglist .rw-multiselect-tag-btn.rw-btn{
    display: none !important;
}

.chip{
    display: inline-block;
    font-size: .75rem;
    font-weight: 500;
    color: #fff;
    line-height: 20px;
    padding: 3px 13px;
    margin: .0rem !important;
    border-radius: 2px;
    background-color: #ffa500;
    cursor: pointer;
}
.field-chips{
    height: calc( 100vh - 500px );
    overflow: auto;
}
