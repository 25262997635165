.student-by-course-accordion .accordion-header{
    background-color: #fff !important;
    border-bottom: 1px solid #FC466B;
    border-left: 5px solid #FC466B;
    border-radius: 5px !important;
}

.accordion-section p{
    color: #274ba0 ;
}

/* ACCORDION INSIDE SECTION  */

.accordion-register-list{
    border-top: 1px solid #FC466B;
    border-bottom: 1px solid #FC466B;
    border-top-right-radius: none !important;
    border-top-left-radius: none !important;
}

 .card-header p{
    font-size: 14px !important;
    font-weight: bold;
}


/* COMBOBOX FILTER */
.rw-widget-picker input::placeholder{
    font-style: italic;
}

