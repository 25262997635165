/* SEARCH AND FILTER BAR */


.dropdown-menu{
    position: absolute;
    will-change: transform; 
    top: 0px; left: 0px; 
    transform: translate3d(51px, 43px, 0px)
}

.filter-select{
    padding-top: 6px;
    padding-bottom: 6px;
}

.excel-button{
    width: 25px !important;
}
/* COLUMN HEADERS  */


.registration-list, .registration-list-student{
    background-color: #fff;
}

.registration-list{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    /* border-bottom: 1px solid #FC466B; */
}

.registration-list-student{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    /* box-shadow: 0 15px 20px 0 rgba(0,0,0,.1)!important; */
}

.student-title button{
    padding: 0;
    border: none;
    background: none;
}

.student-title{
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* STUDENT ROW  */

.student-detail{
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.student-detail u{
    color: #274ba0;
}

.attachment-link{
    font-size: .75rem !important;
}

.cursor-pointer{
    cursor: pointer;
}
/* STUDENT ROW END */

