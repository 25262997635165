.dashboard-heading{
    text-align: left;
    margin-left: 1.9%;
}

.dashboard-heading{
    border-left: 3px solid #ffa500;
}

.filter-list{
    text-align: left;
    padding-bottom: 2px;
    margin-left: 1.9%;
}

.filter-list ul{
    list-style: none;
}

.filter-list ul li{
    display: inline-block;
}

.my-courses-card.show{
    height:53vh;
    overflow:auto
}

.product-section{
    /* text-align: left; */
    margin-top: 20px;
}

.product-card {
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
}
.product-wrapper{
    padding: 5%;
    line-height: 15px;
    font-size: 0.8rem;
}

.product-wrapper a{
    text-decoration: none;
    color: #0055a6;
    font-size: 0.8rem;
}

.product-wrapper p strong{
    color : #232323;
}

.product-wrapper p , hr{
    color : #777777;
}

.product-images{
    height: 40%;
    width: 100%;
    background-color: #ffa500;
}

.row{
    width: 100% !important;
    margin: 0;
}

.course-card{
    margin-bottom: 20px;
    text-align: left;
    border: none;
    position: relative;
    height: auto;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,.1)!important;
    border-radius: 18px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
}

.course-name{
    margin-bottom: 10px;
    color: #274ba0 !important;
    font-weight: 500 !important;
}

.rating{
    border-right: 1.25px solid #dd1d27;
}

.rating p span{
    background-color: #ffa500;
    color: #fff;
    border-radius: 4px;
}

.instructor a{
    color: #1e83f0;
}

.filter-input-append .input-group-text{
    background-color: #274ba0 !important;
    color: #ffa500;
}

.card-text{
    color: #232323 !important;
    line-height: 10px;
}

.card-text span{
    color: #777777 !important;
}

.product-img{
    width: 100%;
    height: 150px;
    background-color: lightslategray;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.details-button{
    border-radius: 25px;
    background-color: #274ba0 !important;
}

.details-button:hover{
    color: white !important;
    background-color:#0055a6 !important;
}

.pay-button{
    border-radius: 25px;
    background-color: #ffa500 !important;
}

.pay-button:hover{
    color: white !important;
    background-color:#ffa500 !important;
}

.price-section p{
    color: #274ba0
}

.price-section i{
    color: #ffa500;
    vertical-align: middle;
    margin-right: 0.5rem;
}

.button{
    text-align: center;
    padding: 0.7rem 2.2rem;
    /* border-radius: 20px; */
    font-size: 0.8rem;
    border: none;
    background-color:#d71b26;
    color: #ffffff;
}



.search{
    margin-top: 5px;
}

.details{
    padding: 0.7rem 1.7rem;
    position: relative;
    left: 50%;
    transform: translate(-50%,31%);
    /* border: 1.5px solid #0055a6; */
    background-color: #d71b26;
    color: #ffffff;
    font-weight: 600;
}
/* 
.details:hover{
  color:  #ffffff;
  background-color: #0055a6; 
} */

select{
    font-size: 0.8rem;
    padding: 0.7rem;
    margin-top: 3px;
    border-radius: 3px;
    border-color: #cccccc;
    color: #777777;
}

.filter-icon{
    margin-top: 3px;
}

.filter-icon i{
    color: #777777;
    font-size: 1.2rem;
    margin-right: 5px;
}

form{
    width: 100%;
}

.big-font{
	font-size: 0.95rem !important;
}



@media only screen and (max-width:899px){
    .filter-list{
        text-align: center;
        margin:0;
        padding: 8px 5%;
    }

    .filter-list li{
        margin: 0;
        width: 100%;
    }
    .filter-list select{
        width: 100%;
    }
}

@media only screen and (min-width:900px){

    .product-section{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .product-card{
        width: 100%;
    }

    .filter-list ul {
        padding: 0px;
    }
    .filter-list li{
        margin-right: 1rem;
    }


}

@media only screen and (min-width:1100px){
    .product-card{
        width: 33%;
    }
}

@media only screen and (min-width:1300px){
    .product-card{
        width: 25%;
    }
}